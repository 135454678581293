import React, { useEffect,useState } from "react";
import Axios from "axios";
import "./blogpage.css";
import image from "../../set/blog-2.jpg";
import image1 from "../../set/blog-4.jpg";
import image2 from "../../set/blog-5.jpg";

const BlogPage = () => {
  const data = [
    {
      imgg: image,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image1,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image2,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially.",
    },
    {
      imgg: image,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image1,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image2,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially.",
    },
    {
      imgg: image,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image1,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially. ",
    },
    {
      imgg: image2,
      name: "TOURS & TRAVEL",
      title:
        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially.",
    },
  ];
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    Axios.get("http://localhost:3000/BlogSelectLimit")
      .then((res) => setBlog(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="blogs">
      <div className="container">
        <div className="textt">
          <h1>Our Blog</h1>
        </div>
        <div className="content-blog">
          {blog.map((d, index) => (
            <div className="blog" key={index}>
              <div className="image">
                <img src={`http://localhost:3000/${d.image}`} alt="" />
              </div>
              <div className="title">
                <h1>{d.title}</h1>
                <p>{d.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPage;
