import React, { useEffect,useState } from "react";
import Axios from "axios";
import Navbar from "../components/Navbar/Navbar";
import Aboutpage from "../components/About/About";
import Footer from "../components/Footer/Footer";
import Service from "../components/Service/Service";
import Guides from "../components/Guides/Guides";
import Blog from "../components/Content/Content";
const About = () => {
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const packageItems = [
    ...new Set(destinations.map((destination) => destination.category)),
  ];

  useEffect(() => {
    Axios.get("http://localhost:3000/destinationsSelect")
      .then((res) => setDestinations(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Navbar
        title="About Us"
        destinations={destinations}
        packageItems={packageItems}
        setSelectedDestination={setSelectedDestination}
        setSelectedDate={setSelectedDate}
      />
      <Aboutpage />
      <Service/>
      <Guides/>
      <Footer />
    </div>
  );
};

export default About;
